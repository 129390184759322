<template>
    <CRow v-if="order">
        <CCol col="12" xl="6">
            <CCard>
                <CCardHeader>Sipariş Detayı</CCardHeader>
                <CCardBody>
                    <dl class="row" v-for="item in fields" :key="item.label">
                        <dt class="col-sm-3">{{ item.label }}</dt>
                        <dd class="col-sm-9">
                            {{ (item.getValue ? item.getValue(order) : order[item.field]) || '-' }}
                        </dd>
                    </dl>

                    <h2>Ürünler</h2>

                    <dl class="row" v-for="item in order.products" :key="item.id">
                        <dt class="col-sm-3">{{ item.product.name }}</dt>
                        <dd class="col-sm-9">
                            {{ item.count }} Adet {{ item.size }} / {{ item.price }} {{  order.address.country === 'TR' ? 'TL' : 'USD' }}
                            <small>{{ item.campaign ? item.campaign.text : '' }}</small>
                        </dd>
                    </dl>


                    <CAlert v-on:click="closeAlert()" v-if="alert.isOpen" show :color="alert.color">
                        {{ alert.message }}
                    </CAlert>
                </CCardBody>
                <CCardFooter class="card-action-footer">
                    <CRow>
                        <CCol col="9">
                            Aksiyonlar
                        </CCol>
                        <CCol col="3">
                            <CButton
                                v-if="['waiting-for-approval', 'returned', 'rejected'].indexOf(order.status) > -1"
                                block
                                variant="outline"
                                :color="order.status === 'waiting-for-approval' ? 'success' : 'warning'"
                                v-on:click="changeOrderStatus('sent', order._id)"
                            >
                                Kargolandı
                            </CButton>
                            <CButton
                                v-if="order.status === 'sent'"
                                block
                                variant="outline"
                                color="danger"
                                v-on:click="changeOrderStatus('returned', order._id)"
                            >
                                İade Edildi
                            </CButton>
                            <CButton
                                v-if="order.status === 'sent'"
                                block
                                variant="outline"
                                color="warning"
                                v-on:click="changeOrderStatus('rejected', order._id)"
                            >
                                İptal Edildi
                            </CButton>
                        </CCol>
                    </CRow>
                </CCardFooter>
            </CCard>
        </CCol>
        <CCol>
            <CCard>
                <CCardHeader>Şüpheli hareketler</CCardHeader>
                <CCardBody>
                    <div>
                        <CCard 
                            v-for="(item, index) in order.surveillances"
                            :key="index"
                        >
                            <CButton 
                                block 
                                color="link" 
                                class="text-left shadow-none card-header" 
                                @click="activeInformationIndex === index ? activeInformationIndex = -1 : activeInformationIndex = index" 
                            >
                                <h5 class="m-0">{{ item.firstname }} {{ item.lastname }}</h5>
                            </CButton>
                            <CCollapse
                                :show="activeInformationIndex === index"
                            >
                                <CCardBody>
                                    <dl class="row" v-for="field in fields" :key="field.label">
                                        <dt class="col-sm-3">{{ field.label }}</dt>
                                        <dd class="col-sm-9">
                                            {{ (field.getValue ? field.getValue(item.order) : item.order[field.field]) || '-' }}
                                        </dd>
                                    </dl>
                                    <dl class="row">
                                        <dt class="col-sm-3">Not</dt>
                                        <dd class="col-sm-9">
                                            {{ item.message }}
                                        </dd>
                                    </dl>
                                </CCardBody>
                            </CCollapse>
                        </CCard>
                    </div>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import { getOrderStatus, getPaymentMethod } from '../../utils';


async function getOrderDetail(id) {
    const response = await axios({
        method: 'get',
        url: `/orders/${id}`,
    });

    return response.data;
}

export default {
    name: 'OrderDetail',

    data () {
        return {
            alert: {
                isOpen: false,
                color: '',
                message: ''
            },
            fields: [
                {
                    field: '',
                    getValue(data) { return `${data.author.firstname} ${data.author.lastname}` },
                    label: 'İsim',
                },
                {
                    field: 'email',
                    label: 'E-Posta adresi',
                    getValue(data) { return data.author.email },
                },
                {
                    field: 'phone',
                    label: 'Telefon numarası',
                    getValue(data) { return data.author.phone },
                },
                {
                    label: 'Sipariş durumu',
                    getValue(data) { return getOrderStatus(data.status) }
                },
                {
                    label: 'Ödeme yöntemi',
                    getValue(data) { return getPaymentMethod(data.paymentMethod) }
                },
                {
                    field: 'paymesReference',
                    label: 'Paymes referans numarası',
                },
                {
                    field: 'message',
                    label: 'Sipariş notu',
                },
                {
                    field: 'price',
                    label: 'Toplam Tutar',
                    getValue(data) { return data.totalPrice },
                },
                {
                    label: 'Adres',
                    getValue(data) {
                        return `${data.address.address} ${data.address.city}/${data.address.country}`;
                    }
                },
                {
                    label: 'Sipariş Tarihi',
                    getValue(data) {
                        return moment(data.date).format('LLL');
                    }
                },
            ],
            order: null,
            activeInformationIndex: -1
        }
    },

    methods: {
        getOrderStatus: getOrderStatus,
        getPaymentMethod: getPaymentMethod,
        async changeOrderStatus(status, orderId) {
            const response = await axios({
                method: 'put',
                url: `/orders/${orderId}/status`,
                data: { status }
            });

            if (response && response.status === 200) {
                this.order = response.data;
                this.alert = {
                    isOpen: true,
                    color: 'success',
                    message: 'Siparişin durumu başarıyla değiştirildi'
                };
            } else {
                this.alert = {
                    isOpen: true,
                    color: 'danger',
                    message: 'İşlem gerçekleştirilirken bir hata oluştu'
                };
            }
        },
        closeAlert() { this.alert.isOpen = false; }
    },

    async mounted() {
        const data = await getOrderDetail(this.$route.params.id);
        this.order = data;
    }
}
</script>
