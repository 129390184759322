const messages = {
    'waiting-for-payment': 'Ödeme Bekleniyor',
    'waiting-for-approval': 'Kargolama Bekleniyor',
    'sent': 'Kargolandı',
    'returned': 'İade Edildi',
    'rejected': 'Sipariş Reddedildi'
};


export default (status) => messages[status];
